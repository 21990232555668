<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button @click="$router.push('/tabs/clubs/' + this.$route.params.id)"></ion-back-button>
        </ion-buttons>
        <ion-title v-text="__('interface.sessions')"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <sessions-list v-for="(items, key) in sessionGroups" :title="key" :key="key" :items="items"/>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonPage, IonRefresher, IonRefresherContent, loadingController} from "@ionic/vue";
import {API} from "@/utils/apiWrapper";
import SessionsList from '@/views/Sessions/SessionsIndex'
import BaseSessions from "../Sessions/BaseSessions";

export default {
  name: "ClubViewTrainers",
  mixins: [BaseSessions],
  components: {IonPage, IonRefresherContent, IonRefresher, SessionsList},
  data() {
    return {
      sessionGroups: []
    }
  },
  methods: {
    doRefresh(event) {
      this.loadData().finally(() => {
        event.target.complete()
      })
    },
    loadData() {
      return API.get('TheSpottApi', '/club-sessions', {
        queryStringParameters: {
          id: this.$route.params.id
        }
      }).then(response => {
        this.sessionGroups = this.parseResponse(response)
      })
    },
  },
  async ionViewWillEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()

    this.loadData().finally(() => {
      loading.dismiss()
    })
  }
}
</script>